import '@cdm/quickenloans-library/lib/index.css';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import WebFont from 'webfontloader';
import { onRouteUpdate as onRouteUpdateFn } from './gatsby-apis/onRouteUpdate';
import ChatWrapper from "./src/components/Molecules/ChatWrapper/ChatWrapper";
import './src/StyleGuide/styles/global.qld.scss';
import './src/StyleGuide/styles/global.scss';
import './src/StyleGuide/styles/global.typography.scss';
import { VideoProvider } from './src/context/videoContext';

const typekitId = 'cie5zve';

WebFont.load({
    typekit: {
        id: typekitId
    }
})

export const wrapRootElement = ({ element }) => (
    // TODO: fix the video provider, is failing due to the window object not being available in SSR
    // <VideoProvider>
    <>
        <ChatWrapper />
        <CookiesProvider>{element}</CookiesProvider>
    </>
    // </VideoProvider>
);

export const onRouteUpdate = onRouteUpdateFn;